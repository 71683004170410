
import {Options, Vue} from 'vue-class-component';

import Image from "@/types/Image";

@Options({
  props: {
    image: Object as () => Image,
    position: String,
  },
})
export default class ImageContainer extends Vue {

  image!: Image;
  loaded = false;

  private get url(): string {
    return this.image.formats && this.image.formats.large ? this.image.formats.large.url : this.image.url;
  }

  private previousImage() {
    this.$emit('previousImage');
  }

  private nextImage() {
    this.$emit('nextImage');
  }

  private get aspectRatio() {
    const c = ImageContainer.gcd(this.image.width, this.image.height);
    return "" + (this.image.width / c) + " " + (this.image.height / c);
  }

  private handleLoad() {
    this.loaded = true;
  }

  private static gcd(a: number, b: number): number {
    if (b > a) {
      const temp = a;
      a = b;
      b = temp
    }
    while (b != 0) {
      const m = a % b;
      a = b;
      b = m;
    }
    return a;
  }
}
