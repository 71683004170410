
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';

import ImageGallery from '@/components/ImageGallery.vue'
import Footer from "@/components/Footer.vue";
import Pattern from "@/components/Pattern.vue";

import Event from "@/types/Event";

@Options({
  props: {
    slug: String,
  },
  components: {
    ImageGallery,
    Footer,
    Pattern,
  },
})
export default class EventView extends Vue {

  private static dateFormatDay = 'DD';
  private static dateFormatMonth = 'DD.MM';
  private static dateFormatYear = 'DD.MM.YYYY';

  slug!: string;

  rendered = false;

  public async mounted(): Promise<void> {
    await this.store.dispatch('fetchEvents');
    document.title = `Laurel - ${this.event.title}`;
    this.rendered = true;
  }

  private get event(): Event {
    return this.store.getters.event(this.slug);
  }

  private get openingTimes(): string {
    const event = this.event;
    const startDate = moment(event.startDate);
    const endDate = event.endDate ? moment(event.endDate) : null;

    if (!endDate || startDate.isSame(endDate)) {
      return startDate.format(EventView.dateFormatYear);
    }

    if (startDate.isSame(endDate, 'month')) {
      return `${startDate.format(EventView.dateFormatDay)} — ${endDate.format(EventView.dateFormatYear)}`;
    }

    if (startDate.isSame(endDate, 'year')) {
      return `${startDate.format(EventView.dateFormatMonth)} — ${endDate.format(EventView.dateFormatYear)}`;
    }

    return `${startDate.format(EventView.dateFormatYear)} — ${endDate.format(EventView.dateFormatYear)}`;
  }

  private formatMarkdown(markdown: string): string {
    let str = markdown;
    const elements = markdown.match(/\[.*?\)/g);
    if (elements != null && elements.length > 0) {
      for (const el of elements) {
        if (el !== undefined) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Object is possibly 'null'.
          const txt = el.match(/\[(.*?)\]/)[1]; // get only the txt
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Object is possibly 'null'.
          const url = el.match(/\((.*?)\)/)[1]; // get only the link
          str = str.replace(el, `<a style="color:black;" href="${url}"
            target="_blank">${txt}</a>`);
        }
      }
    }
    return str;
  }

}
